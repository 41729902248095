import React, { useEffect, useState, useContext } from "react";
import { usePlaidLink } from "react-plaid-link";
import { DataContext } from "../../../context/DataContext";
import { Loader, Group } from "@mantine/core";
import useStatusCheck from "../../../hooks/useStatusCheck";
import CenterLoadingOverlay from "../../../components/center-loading-overlay";
import * as Sentry from "@sentry/react";
import { startTransaction } from "@sentry/core"; // Import startTransaction from @sentry/core

const Plaid = ({ onClose }) => {
  const [token, setToken] = useState(null);
  const [plaidEvent, setPlaidEvent] = useState(null);
  const [plaidEventMetaData, setPlaidEventMetaData] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [template, setTemplate] = useState([]);
  const [closed, setClosed] = useState(false);
  const [done, setDone] = useState(false);
  const [linkSessionID, setLinkSessionID] = useState(null);
  const { settings, data } = useContext(DataContext);
  useStatusCheck(done, false, "idmax");

  // Initialize a Sentry transaction at the beginning of the component

  // Initialize Plaid Link with an empty token which will be replaced once the token is fetched.
  const { open, ready, error } = usePlaidLink({
    token,
    onSuccess: (public_token, metadata) => {
      setLinkSessionID(metadata.link_session_id);
    },
    onExit: (error, metadata) => {
      setClosed(true);
    },
    onEvent: (eventName, metadata) => {
      if (eventName === "IDENTITY_VERIFICATION_CLOSE_UI") {
        setLoaded(false);
        setDone(true);
      }
      setPlaidEvent(eventName);
      setPlaidEventMetaData(metadata);
    },
  });

  useEffect(() => {
    if (!token && !loaded) {
      data.provider = "PLAID";
      fetch(
        process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      )
        .then((response) => response.json())
        .then((data_returned) => {
          setTemplate(data_returned.continuations.info.template);
          setToken(data_returned.continuations.info.template.info.link_token); // Set the token for Plaid Link
          setLoaded(true); // Indicate that the token loading process is complete
        })
        .catch((error) => {
          console.error("Error fetching the link token:", error);
          Sentry.captureException(error);
        });
    }
  }, [token, loaded, settings.request_id]);

  useEffect(() => {
    if (ready && token) {
      open();
    }
  }, [ready, token, open]);

  useEffect(() => {
    console.log("plaid event", plaidEvent);
    console.log("plaid event", JSON.stringify(plaidEventMetaData));
    console.log("plaid error", error);
    console.log("plaid closed", closed);
  }, [plaidEvent, plaidEventMetaData, error, closed]);

  useEffect(() => {
    if (closed) {
      window.top.postMessage("CLOSE", "*");
    }
  }, [closed]);

  useEffect(() => {
    if (linkSessionID) {
      fetchStatus();
    }
  }, [linkSessionID]);

  const fetchStatus = async () => {
    data.isIDMaxContinue = true;
    data.template = template;
    data.template.info.identity_verification_id = linkSessionID;

    try {
      const response = await fetch(
        process.env.REACT_APP_BACKEND_URL + "/v3/stable/api/frontend/idmax",
        {
          method: "POST",
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data_returned = await response.json();
    } catch (error) {
      console.error("Error fetching status:", error);
      Sentry.captureException(error);
    }
  };

  // Render a loading indicator if we are waiting for the token
  if (!loaded) {
    return (
      <CenterLoadingOverlay
        content={
          <>
            <Group position="center">
              <Loader />
            </Group>
          </>
        }
      />
    );
  }

  return null;
};

export default Sentry.withProfiler(Plaid);
